/* jshint bitwise: false*/

(function() {
  'use strict';

  function UtilsService(
    $q,
    $uibModal,
    Event,
    EventSection,
    EventsStore,
    EventSearch,
    Events,
    Report,
    Reports,
    Form,
    GoalsStore,
    BlueprintUtils,
    Relations,
    RelationUtils,
    Blueprints,
    UsersStub,
    ApiIDList,
    DBList,
    Roles,
    Utils,
    Network
  ) {
    var service = {};

    var makeDocs = function(item) {
      var obj;
      if (item.doc.type === 'event') {
        obj = new Event(
          item.doc,
          { extras: item.extras, eventType: item.eventType, files: item.files }
        );
      } else if (item.doc.type === 'eventSection') {
        obj = new EventSection(item.doc, { extras: item.extras, eventType: item.eventType });
      }
      return {
        id: obj.doc._id,
        main: obj
      };
    };

    service.preCache = function() {
      return $q.all([
        // EventTypes.findAll(),
        Blueprints.findAll(),
        // Events.getAllExtras(options),
        Blueprints.flatten()
      ]);
    };

    service.getList = function(searchCode, searchModel, options) {
      if (Network.isOffline()) {
        return service.getOfflineList(searchCode, searchModel, options);
      }

      return service.getOnlineList(searchCode, searchModel, options);
    };

    service.getOnlineList = function(searchCode, searchModel, options) {
      options = options || {};
      return EventSearch.getSearch(searchCode)
        .then(function(search) {
          var defaultFilter = _.assignIn(
            {},
            // { hideOwnMerged: true },
            search.defaultFilter || {},
            options.defaultFilter || {},
            // (resolvedFilter || {}).filter,
            { extraFilter: (options.resolvedFilter || {}).filters || [] },
            { extraTransformedFilter: (options.resolvedTransformedFilter || {}).filters || [] }
          );

          var searchfn;
          var own = options.own || false;

          if (own) {
            searchfn = Events.searchOwnIds.bind(Events);
          } else {
            searchfn = Events.searchIds.bind(Events);
          }

          var listOptions = {
            search: search,
            model: searchModel,
            idField: 'id',
            defaultFilter: defaultFilter,
            makeDocs: makeDocs,
            searchFn: searchfn,
            fetchFn: Events.fetchIds.bind(Events)
          };
          if (options.listOptions) {
            _.assignIn(listOptions, options.listOptions);
          }

          return new ApiIDList(listOptions);
        });
    };

    service.getOfflineList = function(searchCode, searchModel, options) {
      options = options || {};
      var searchFn;
      if (options.resolvedFilter) {
        var fltrs = Utils.filtersToSearchModel(options.resolvedFilter.filters);
        searchFn = EventSearch.preFilteredSearch(fltrs);
        // searchFn = EventSearch.preFilteredSearch(options.resolvedFilter.filters);
      } else {
        searchFn = EventSearch.getSearch(searchCode);
      }
      return searchFn
        .then(function(search) {
          var defaultFilter = _.assignIn(
            { hideOwnMerged: true },
            options.defaultFilter || {},
            search.defaultFilter || {},
            (options.resolvedFilter || {}).filter
          );

          return EventsStore.loadGrouped()
            .then(function(data) {
              var listOptions = {
                search: search,
                model: searchModel,
                idField: 'id',
                defaultFilter: defaultFilter
              };
              if (options.listOptions) {
                _.assignIn(listOptions, options.listOptions);
              }

              var list = new DBList(listOptions);
              list.doLoadItems(data);
              return list;
            });
        });
    };

    service.aggregateEventsFromItems = function(items) {
      var tags = {};
      var promises = _.map(items, function(item) {
        return item.init()
          .then(function(item) {
            var count;
            item.categories.forEach(function(tag) {
              count = tags[tag] || 0;
              tags[tag] = count += 1;
            });
          })
          .catch(function(error) {
            // We can only ignore this as if event cannot be initialized then it is seriously wrong
            // and there is no way to retrieve tags
            console.log('Error when initializing event', error);
          });
      });
      return $q.all(promises)
        .then(function() {
          return tags;
        });
    };

    service.aggregateEventsFromApi = function(aggs) {
      var tags = {};
      _.forEach(aggs, function(item) {
        tags[item.key] = item.doc_count;
      });
      return $q.when(tags);
    };

    service.propagateTags = function(tags) {
      return Blueprints.findPathFor({ keys: _.keys(tags) })
        .then(function(paths) {
          var treeTags = {};
          var count;
          paths.forEach(function(item) {
            // if (item.value.nodeType !== 'leaf') {
            //   return;
            // }

            count = tags[item.key];
            item.path.forEach(function(node) {
              treeTags[node.key] = treeTags[node.key] || { tags: 0, count: 0 };
              treeTags[node.key].tags += count;
              treeTags[node.key].count += 1;
            });
          });
          return treeTags;
        });
    };

    service.mergeTagsWithBlueprints = function(treeTags, blueprints, options) {
      // var firstCall = true;
      function assignAggs(categories, aggregateBy) {
        options = options || {};
        var keepEmpty = (_.isUndefined(options.keepEmpty)) ? true : options.keepEmpty;
        var toRemove = [];
        var tags;
        var toShow;
        var total = 0;
        var used = 0;
        var type;

        categories.forEach(function(category) {
          if (_.isUndefined(category)) {
            return;
          }

          if (aggregateBy) {
            type = aggregateBy;
          } else if (options.type === 'blueprintDefault' && category.aggregateBy) {
            type = category.aggregateBy;
          } else {
            type = options.type;
          }

          if (category.categories && category.categories.length) {
            tags = assignAggs(category.categories, type);
          } else {
            tags = {
              total: 0,
              used: 0
            };
          }

          tags.total += 1;
          if (_.has(treeTags, category._id)) {
            _.assignIn(tags, treeTags[category._id]);
            tags.used += 1;
          } else {
            tags.tags = 0;
          }

          category.tags = tags;

          var showPerc;
          if (category.categories && category.categories.length) {
            showPerc = Math.round((100 * tags.used) / tags.total);
            showPerc = 'coverage: ' + tags.used + ' out of ' + tags.total + ' - ' + showPerc + '%';
          } else {
            showPerc = tags.tags + ' events';
          }

          var showTags = tags.tags;
          showTags = showTags + ' tag' + (showTags > 1 ? 's' : '');

          switch (type) {
            case 'coverage':
              toShow = showPerc;
              break;
            case 'countTags':
              toShow = showTags;
              break;
            case 'all':
              toShow = showTags + ', ' + showPerc;
              break;
            default:
              toShow = '';
              break;
          }

          if (toShow.length > 0) {
            category.name = category.name + ' (' + toShow + ')';
          }

          if (tags.tags === 0) {
            toRemove.push(category._id);
          }

          total += tags.total;
          used += tags.used;
        });

        // Remove the category as we are not interested in empty categories
        if (!keepEmpty) {
          _.remove(categories, function(item) {
            return toRemove.indexOf(item._id) > -1;
          });
        }

        return {
          total: total,
          used: used
        };
      }

      var result = angular.copy(blueprints);
      assignAggs(result);
      return result;
    };

    service.aggregateBlueprints = function(tags, options) {
      options = options || {};
      options.type = options.type || 'all';

      // Sum the tags up the hierarchy
      var promise = service.propagateTags(tags)

        // Load blueprints
        .then(function(result) {
          var p = options.blueprints ? $q.when(options.blueprints) : Blueprints.findDiscreteOnly();
          return $q.all([result, p]);
        })

        // Assign aggregates count to blueprints
        .then(function(result) {
          return service.mergeTagsWithBlueprints(result[0], result[1], options);
        });

      return promise;
    };

    function updateFieldVisibility(condition, model, fieldId) {
      if (condition) {
        delete model[fieldId];
      }

      return condition;
    }

    service.createFormField = function(field, options, kzOpts) {
      options = options || {};
      kzOpts = kzOpts || {};

      var fid = kzOpts.forceId || field._id;

      var forUser = kzOpts.forUser;
      if (forUser === undefined && kzOpts.event) {
        forUser = kzOpts.event.doc.user;
      }

      var formState = options.formState || {};
      var readOnly = formState.readOnly;

      var fld = {
        fieldId: field._id,
        originalType: field.blueprintType || field.type,
        kzType: field.blueprintType || field.type,
        disabled: readOnly
      };

      if (kzOpts.multiSource) {
        field.originalType = field.blueprintType || field.type;
        field.type = 'multi';
      }

      const defaultFileOptions = {
        accept: ['all'],
        multiple: false,
        sizeLimit: 100,
        fileLimit: 0,
        storeSingle: false
      };

      const fileOptions = {
        accept: field.fileTypes ?? defaultFileOptions.accept,
        sizeLimit: field.sizeLimit ?? defaultFileOptions.sizeLimit,
        multiple: field.multiple ?? defaultFileOptions.multiple,
        fileLimit: field.fileLimit ?? defaultFileOptions.fileLimit
      };

      const turnitInOptions = {
        enabled: field.enableAntiPlagiarism,
        attempts: field.enableAntiPlagiarismAttempts,
        required: field.enableAntiPlagiarismRequired,
        buttonLabel: field.enableAntiPlagiarismTitle
      };
      fld.turnitInOptions = turnitInOptions;

      var promise;
      var loader;
      var restricted = Utils.clearRestricted(field.restricted);
      var discreteFields = ['discrete', 'discrete_multiple', 'likert', 'tree'];
      if (kzOpts.ignoreRestricted) {
        if (_.isUndefined(fld.templateOptions)) {
          fld.templateOptions = {};
        }

        fld.templateOptions.ignoreRestricted = true;
      } else if (restricted.by !== undefined) {
        if (restricted.to !== undefined) {
          fld.hideExpression = function(_$viewValue, _$modelValue, scope) {
            var value = scope.model[restricted.by] || scope.model[restricted.blueprintId],
                condition = restricted.to.indexOf(value) === -1;

            if (_.isArray(value)) {
              condition = _.intersection(value, restricted.to).length === 0;
            }

            var fieldId = field.blueprint ? field.blueprint : fld.fieldId;
            return updateFieldVisibility(condition, scope.model, fieldId);
          };
        } else {
          fld.hideExpression = function(_$viewValue, _$modelValue, scope) {
            var value = scope.model[restricted.by] || scope.model[restricted.blueprintId],
                fieldId = field.blueprint ? field.blueprint : fld.fieldId;
            return updateFieldVisibility(!Utils.bool(value), scope.model, fieldId);
          };
        }
      }

      var f;
      if (field.type === 'multi') {
        f = {
          id: fid,
          type: field.type,
          required: field.isRequired,
          originalType: field.originalType,
          label: field.name,
          helpText: field.helpText
        };

        _.assign(fld, f);
        promise = fld;
      } else if (field.type === 'customField') {
        f = {
          id: fid,
          type: field.blueprintType,
          required: field.isRequired,
          label: field.name,
          helpText: field.helpText,
          kzType: field.type
        };

        if (discreteFields.indexOf(field.blueprintType) !== -1) {
          if (field.blueprintType !== 'tree' && Utils.suitableForSimpleField(field.categories)) {
            f.options = Form.prototype.buildOptions(field.categories);
          } else {
            _.assign(f, {
              type: 'tree',
              controller: ['$scope', function($scope) {
                $scope.tree = field.categories;
                $scope.selected = [];
                $scope.kzTree = '';
                $scope.treeOptions = {
                  selectable: true,
                  searchable: true,
                  multiple: ['discrete', 'likert'].indexOf(field.blueprintType) === -1,
                  icons: {
                    arrowRight: 'icon-right-open-big',
                    arrowDown: 'icon-down-open-big',
                    empty: 'icon-minus'
                  }
                };
              }],
              ngModelAttrs: {
                kzTree: {
                  bound: 'kzTree',
                  attribute: 'kz-tree'
                },
                selected: {
                  bound: 'selected',
                  attribute: 'selected'
                },
                nodes: {
                  bound: 'nodes',
                  attribute: 'nodes'
                },
                options: {
                  bound: 'treeOptions',
                  attribute: 'options'
                }
              },
              ngModelAttrsValues: [
                {
                  name: 'kzTree',
                  value: 'kzTree'
                },
                {
                  name: 'selected',
                  value: 'selected'
                },
                {
                  name: 'nodes',
                  value: 'tree'
                },
                {
                  name: 'options',
                  value: 'treeOptions'
                }
              ]
            });
          }
        } else if (field.blueprintType === 'file') {
          f.multiple = false;
          f.direct = true;
          f.docId = kzOpts.docId;
          f.fileOptions = fileOptions;
          f.turnitInOptions = turnitInOptions;
        }

        _.assign(fld, f);
        promise = fld;
      } else if (field.type === 'blueprint') {
        loader = function() {
          return Blueprints.find(field.blueprint, { cached: true })
            .then(function(blueprint) {
              if (
                !readOnly &&
                _.indexOf(discreteFields, blueprint.blueprintType) > -1
              ) {
                var opts = { user: forUser };
                var blprm;
                if (kzOpts.showAllBlueprints) {
                  blprm = $q.when([blueprint]);
                } else {
                  blprm = BlueprintUtils.getRestrictedBlueprints([blueprint], opts);
                }

                return blprm
                  .then(function(blueprints) {
                    var categories;
                    if (blueprints.length === 1) {
                      categories = blueprints[0].categories;
                    } else {
                      categories = blueprints;
                    }

                    if (blueprint.blueprintType === 'tree' ||
                        !Utils.suitableForSimpleField(categories)) {
                      f = {
                        id: kzOpts.forceId || blueprint._id,
                        controller: ['$scope', function($scope) {
                          $scope.tree = categories;
                          $scope.selected = [];
                          $scope.kzTree = '';
                          $scope.treeOptions = {
                            selectable: true,
                            searchable: true,
                            multiple: blueprint.blueprintType !== 'discrete',
                            icons: {
                              arrowRight: 'icon-right-open-big',
                              arrowDown: 'icon-down-open-big',
                              empty: 'icon-minus'
                            }
                          };
                        }],
                        type: 'tree',
                        label: field.name || blueprint.name,
                        required: field.isRequired,
                        helpText: field.helpText,
                        ngModelAttrs: {
                          kzTree: {
                            bound: 'kzTree',
                            attribute: 'kz-tree'
                          },
                          selected: {
                            bound: 'selected',
                            attribute: 'selected'
                          },
                          nodes: {
                            bound: 'nodes',
                            attribute: 'nodes'
                          },
                          options: {
                            bound: 'treeOptions',
                            attribute: 'options'
                          }
                        },
                        ngModelAttrsValues: [
                          {
                            name: 'kzTree',
                            value: 'kzTree'
                          },
                          {
                            name: 'selected',
                            value: 'selected'
                          },
                          {
                            name: 'nodes',
                            value: 'tree'
                          },
                          {
                            name: 'options',
                            value: 'treeOptions'
                          }
                        ]
                      };
                    } else {
                      f = {
                        id: kzOpts.forceId || blueprint._id,
                        type: field.blueprintType,
                        required: field.isRequired,
                        label: field.name || blueprint.name,
                        helpText: field.helpText,
                        kzType: field.type
                      };
                      f.options = Form.prototype.buildOptions(categories);
                    }
                    return _.assign(fld, f);
                  });
              }
              if (blueprint.blueprintType === 'file') {
                fld.multiple = false;
                fld.direct = true;
                fld.docId = kzOpts.docId;
                fld.fileOptions = fileOptions;
              }

              return _.assign(fld, {
                id: kzOpts.forceId || blueprint._id,
                type: blueprint.blueprintType,
                label: field.name || blueprint.name,
                required: field.isRequired,
                helpText: field.helpText,
                enableAntiPlagiarism: field.enableAntiPlagiarism,
                enableAntiPlagiarismAttempts: field.enableAntiPlagiarismAttempts,
                enableAntiPlagiarismRequired: field.enableAntiPlagiarismRequired,
                enableAntiPlagiarismTitle: field.enableAntiPlagiarismTitle,
                turnitInOptions: turnitInOptions
              });
            });
        };

        f = {
          type: field.blueprintType,
          required: field.isRequired,
          label: 'loading...',
          helpText: field.helpText,
          kzType: field.type,
          multiple: false,
          direct: true,
          loader: loader
        };
        promise = _.assign({}, fld, f);
      } else if (field.type === 'relation') {
        loader = function() {
          return Relations.find(field.relation, { cache: 'cached' })
            .then(function(relation) {
              if (readOnly) {
                return [relation];
              }

              return $q.all([$q.when(relation), RelationUtils.getRestrictedRelations([relation])]);
            })
            .then(function(results) {
              var relation = results[0],
                  restrictedRelations = results[1];
              return $q.all([$q.when(relation), Utils.removeDisabledItems(restrictedRelations)]);
            })
            .then(function(results) {
              var relation = results[0],
                  restrictedRelations = results[1];

              var categories = restrictedRelations;
              // Leaving this in for a reference - we do not want to limit only below
              // but to the same level too

              // If there is only one unrestricted relation, then we can use its categories
              if (restrictedRelations.length === 1 && restrictedRelations[0]._id === relation._id) {
                categories = restrictedRelations[0].categories;
              }

              var relationField = {
                id: kzOpts.forceId || field.relation,
                controller: ['$scope', function($scope) {
                  $scope.tree = categories;
                  $scope.selected = [];
                  $scope.kzTree = '';
                  $scope.treeOptions = {
                    selectable: true,
                    searchable: true,
                    multiple: field.multiple,
                    icons: {
                      arrowRight: 'icon-right-open-big',
                      arrowDown: 'icon-down-open-big',
                      empty: 'icon-minus'
                    }
                  };
                }],
                type: 'tree',
                label: relation.name,
                required: field.isRequired,
                helpText: field.helpText,
                ngModelAttrs: {
                  kzTree: {
                    bound: 'kzTree',
                    attribute: 'kz-tree'
                  },
                  selected: {
                    bound: 'selected',
                    attribute: 'selected'
                  },
                  nodes: {
                    bound: 'nodes',
                    attribute: 'nodes'
                  },
                  options: {
                    bound: 'treeOptions',
                    attribute: 'options'
                  }
                },
                ngModelAttrsValues: [
                  {
                    name: 'kzTree',
                    value: 'kzTree'
                  },
                  {
                    name: 'selected',
                    value: 'selected'
                  },
                  {
                    name: 'nodes',
                    value: 'tree'
                  },
                  {
                    name: 'options',
                    value: 'treeOptions'
                  }
                ]
              };
              return _.assign(fld, relationField);
            });
        };

        f = {
          type: 'tree',
          required: field.isRequired,
          label: 'loading...',
          helpText: field.helpText,
          loader: loader
        };
        promise = _.assign({}, fld, f);
      } else if (field.type === 'role') {
        loader = function() {
          return Roles.find(field.role, { cached: true })
            .then(function(roleDoc) {
              return _.assign(fld, {
                id: kzOpts.forceId || roleDoc._id,
                type: 'discrete_multiple',
                kzType: 'user',
                label: roleDoc.title,
                required: field.isRequired,
                controller: ['$scope', function($scope) {
                  $scope.reload = function(search) {
                    if (search.length < 3) {
                      $scope.options.templateOptions.options = [];
                      return;
                    }

                    var options = { roles: roleDoc._id, rolesAppliesTo: null };
                    if (search) {
                      options.autocomplete_fullname = search;
                    }
                    UsersStub.findAll(options)
                      .then(function(data) {
                        $scope.options.templateOptions.options = _.map(data, function(user) {
                          return {
                            _id: user.username,
                            key: user.username,
                            name: _.trim((user.firstName || '') + ' ' + (user.lastName || '') +
                              ' - ' + (user.email || '')) || user.username
                          };
                        });
                      });
                  };
                }],
                helpText: field.helpText,
                ngModelAttrs: {
                  limit: {
                    bound: 'ng-limit',
                    attribute: 'limit'
                  },
                  closeOnSelect: {
                    bound: 'ng-close-on-select',
                    attribute: 'close-on-select'
                  }
                },
                ngModelAttrsValues: [
                  {
                    name: 'limit',
                    value: 1
                  },
                  {
                    name: 'closeOnSelect',
                    value: true
                  }
                ],
                options: [],
                noFormControl: readOnly,
                template: '<span epf-fullnames user="model[options.key]"></span>'
              });
            });
        };
        f = {
          type: 'discrete_multiple',
          required: field.isRequired,
          label: 'loading...',
          helpText: field.helpText,
          loader: loader
        };
        promise = _.assign({}, fld, f);
      } else if (field.type === 'report') {
        loader = function() {
          return Reports.getGroupByItemId(field.report)
            .then(function(data) {
              var report = new Report(data);
              return _.assign(fld, {
                id: fid,
                type: 'report',
                label: report.doc.title,
                required: field.isRequired,
                helpText: field.helpText,
                ngModelAttrsValues: [
                  {
                    name: 'report',
                    value: data
                  },
                  {
                    name: 'reportData',
                    value: field.reportData
                  },
                  {
                    name: 'variable',
                    value: field.variable
                  },
                  {
                    name: 'display',
                    value: field.display || 'table' // Lets default to original risr/advance
                  },
                  {
                    name: 'charts',
                    value: field.charts || []
                  },
                  {
                    name: 'chartsColumns',
                    value: field.chartsColumns
                  },
                  {
                    name: 'tableColumns',
                    value: field.tableColumns
                  },
                  {
                    name: 'extra',
                    value: { user: forUser }
                  },
                  {
                    name: 'size',
                    value: field.size
                  }
                ],
                report: report,
                reportData: field.reportData
              });
            });
        };

        f = {
          type: 'tree',
          required: field.isRequired,
          label: 'loading...',
          helpText: field.helpText,
          loader: loader
        };
        promise = _.assign({}, fld, f);
      } else if (field.type === 'goal') {
        _.assign(fld, {
          id: fid,
          type: 'goal',
          label: '',
          required: field.isRequired,
          helpText: field.helpText,
          ngModelAttrsValues: [
            {
              name: 'dueDate',
              value: field.dueDate
            },
            {
              name: 'periods',
              value: field.periods
            },
            {
              name: 'predefinedGoals',
              value: field.definition
            },
            {
              name: 'customsSettings',
              value: field.customsSettings
            },
            {
              name: 'arePredefinedGoalsRedefinable',
              value: field.arePredefinedGoalsRedefinable
            },
            // {
            //   name: 'canLinkExistingGoals',
            //   value: field.canLinkExistingGoals
            // },
            {
              name: 'sectionId',
              value: field.sectionId
            },
            {
              name: 'eventTypeId',
              value: kzOpts.eventTypeId
            }
          ]
        });
        promise = fld;
      } else if (field.type === 'text') {
        _.assign(fld, {
          id: fid,
          type: field.type + '_' + field.textType,
          label: field.content,
          helpText: field.helpText
        });
        promise = fld;
      } else if (field.type === 'signature') {
        _.assign(fld, {
          id: fid,
          type: 'signature',
          label: field.name,
          required: field.isRequired,
          helpText: field.helpText
        });
        promise = fld;
      }

      return promise;
    };

    service.openLinkingPopup = function(model, attrName, dbOptions, options) {
      var opts = {
        goalset_state: 'open',
        size: 1000
      };

      if (!_.isUndefined(options.filter)) {
        opts.eventType_versionGroupId = options.filter.eventType_versionGroupId;
      }

      return GoalsStore.fetchLight(opts, { own: !dbOptions.remoteUser })
        .then(function(goals) {
          var linkableTargets = _.chain(goals)
            .map(function(goal) {
              return goal.targets; // list of list targets
            })
            .reduce(function(sum, item) {
              return sum.concat(item); // list of targets
            }, [])
            .filter(function(target) {
              var conditions = [];
              if (!options.generalTargets && options.eventType) {
                conditions.push(target.getRelevantEventTypes().indexOf(options.eventType) !== -1);
              }

              if (options.generalTargets) {
                conditions.push(_.isEmpty(target.doc.conditions));
              }

              return _.some(conditions);
            })
            .map(function(target) {
              return target.doc._id;
            })
            .value();

          return $q.all([goals, linkableTargets]);
        })
        .then(function(result) {
          var goals = result[0];
          options.highlightObjs = result[1];

          $uibModal.open({
            animation: true,
            templateUrl: 'app/components/events/partials/event-link-goals.html',
            controller: [
              '$scope',
              '$uibModalInstance',
              'model',
              'attrName',
              'goals',
              'options',
              function(
                $scope,
                $uibModalInstance,
                model,
                attrName,
                allGoals,
                options
              ) {
                $scope.allGoals = angular.copy(allGoals);
                $scope.options = options;

                if (options.highlightObjs && options.showSuggestedGoals) {
                  var goals = angular.copy(allGoals);
                  $scope.suggestedGoals = _.chain(goals)
                    .filter(function(goal) {
                      // filter targets
                      goal.targets = _.filter(goal.targets, function(target) {
                        return _.indexOf(options.highlightObjs, target.doc._id) > -1;
                      });

                      return goal.targets.length > 0;
                    })
                    .map(function(goal) {
                      goal.suggested = true;
                      return goal;
                    })
                    .value();

                  goals = angular.copy(allGoals);
                  $scope.goalsContaningGlobalTargets = _.filter(goals, function(goal) {
                    // filter targets
                    goal.targets = _.filter(goal.targets, function(target) {
                      var conditions = target.doc.conditions || [];
                      return conditions.length === 0;
                    });

                    return goal.targets.length > 0;
                  });

                  $scope.opts = angular.copy(options);

                  // hack as it can be anything
                  $scope.opts.defaultListOrder = 'suggestedAndDueDate';
                  $scope.suggestedGoals = $scope.suggestedGoals.concat(
                    $scope.goalsContaningGlobalTargets
                  );
                }

                $scope.allGoalsShown = false;
                if (_.isUndefined($scope.suggestedGoals) && _.isEmpty($scope.suggestedGoals)) {
                  $scope.allGoalsShown = true;
                }

                $scope.showAllGoals = function() {
                  $scope.allGoalsShown = true;
                };

                $scope.links = {};
                _.forEach(model[attrName], function(obj) {
                  var attr = obj;
                  if (options.linkType === 'target') {
                    attr = obj.goalId + '|' + obj.targetId + '|' + obj.eventTypeVersionGroupId;
                  }

                  $scope.links[attr] = true;
                });

                $scope.dismiss = function() {
                  $uibModalInstance.dismiss('cancel');
                };

                $scope.save = function() {
                  var links = [];
                  _.forEach($scope.links, function(value, key) {
                    if (value) {
                      var val = key;
                      if (options.linkType === 'target') {
                        var keySplitted = key.split('|');
                        val = {
                          goalId: keySplitted[0],
                          targetId: keySplitted[1],
                          eventTypeVersionGroupId: keySplitted[2]
                        };
                      }
                      links.push(val);
                    }
                  });

                  model[attrName] = links;

                  $uibModalInstance.dismiss('cancel');
                };
              }
            ],
            size: 'lg',
            resolve: {
              model: function() {
                return model;
              },
              attrName: function() {
                return attrName;
              },
              goals: function() {
                return goals;
              },
              options: function() {
                return options;
              }
            }
          });
        });
    };

    service.openLinkingEventToTargetsPopup = function(
      eventSection,
      remoteUser,
      goalCategory
    ) {
      var model = eventSection.doc.meta,
          attrName = 'linkedTargets',
          options = {
            title: 'Link targets',
            showSearch: true,
            linkType: 'target',
            showTargets: true,
            showGoalSetName: true,
            eventType: eventSection.eventType.versionGroupId,
            showSuggestedGoals: true
          },
          dbOptions = {};

      if (goalCategory) {
        options.title = goalCategory.name;
        options.filter = goalCategory.filter;
      }

      if (remoteUser) {
        dbOptions.username = remoteUser;
      }

      service.openLinkingPopup(model, attrName, dbOptions, options);
    };

    return service;
  }

  UtilsService.$inject = [
    '$q',
    '$uibModal',
    'EventFactory',
    'EventSectionFactory',
    'EventsStore',
    'EventSearch',
    'EventsService',
    'ReportFactory',
    'ReportTemplatesService',
    'FormsService',
    'GoalsStore',
    'BlueprintUtils',
    'RelationsService',
    'RelationUtils',
    'BlueprintsService',
    'UsersStubService',
    'APIIDListFactory',
    'ListFactory',
    'RolesService',
    'UtilsService',
    'NetworkService'
  ];

  angular.module('blocks.utils')
    .service('EventUtils', UtilsService);
})();
