<h2 mat-dialog-title>Register SSO Credential</h2>
<form [formGroup]="proxyForm">
  <mat-dialog-content>
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Username</mat-label>
      <input matInput formControlName="username" required>
      <mat-error *ngIf="proxyForm['username'].hasError('required')">Username is required</mat-error>
    </mat-form-field>

    <mat-dialog-actions align="end">
      <button mat-button (click)="onCancel()">Cancel</button>
      <button mat-raised-button color="primary" type="submit" [disabled]="proxyForm.invalid" (click)="onSubmit()">Submit</button>
    </mat-dialog-actions>
  </mat-dialog-content>
</form>
