<h2 mat-dialog-title>Register Local Credential</h2>
<form [formGroup]="localForm">
  <mat-dialog-content>
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Username</mat-label>
      <input matInput formControlName="username" required>
      <mat-error *ngIf="localForm.get('username')?.hasError('required')">Username is required</mat-error>
    </mat-form-field>

    <!-- Show passwordOptions only if isForRemoteUser is true -->
    <mat-form-field *ngIf="isForRemoteUser" appearance="outline" class="w-full">
      <mat-label>Password Options</mat-label>
      <mat-select formControlName="passwordOptions">
        <mat-option value="customPassword">Set a password on behalf of the user</mat-option>
        <mat-option value="letTheUserChooseOne">Send an email and let the user set a password</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Show password field based on the conditions defined in updatePasswordFieldVisibility() -->
    <div *ngIf="!isForRemoteUser || localForm.get('passwordOptions')?.value === 'customPassword'">
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Password</mat-label>
        <input matInput type="password" autocomplete="new-password" formControlName="password" required>
        <mat-error *ngIf="localForm.get('password').hasError('required')">
          Password is required
        </mat-error>
      </mat-form-field>
      <p>
        <app-password-strength [control]="localForm.get('password')"></app-password-strength>
      </p>

      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Re-type Password</mat-label>
        <input matInput type="password" formControlName="passwordConfirmation" required autocomplete="new-password" passwordrules="required: upper; required: lower; required: digit; required: [-().&@?'#,/&quot;+]" />
        <mat-error *ngIf="localForm.get('passwordConfirmation').hasError('required')">
          Re-type Password is required
        </mat-error>
      </mat-form-field>
      <div *ngIf="localForm.hasError('mismatch')">
        Passwords do not match.
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="localForm.invalid" (click)="onSubmit()">Submit</button>
  </mat-dialog-actions>
</form>
