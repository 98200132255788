import { inject, Injectable } from '@angular/core';
import { Api, Response } from "@fry/core/api";
import {
  ApiCredential,
  CredentialTypes,
  LocalCredential,
  ProxyCredential
} from "@fry/system/account/credential/credential";
import { AuditLog } from "@fry/core/audit-logs";
import { ClientId } from "@fry/core/organisation";


interface GetCredentialsResponse {
  credentials: (LocalCredential | ProxyCredential | ApiCredential)[];
  audit_logs: AuditLog[];
}

@Injectable({
  providedIn: 'root'
})
export class CredentialService {
  private api = inject(Api);

  async getCredentials(accountId?: string | null): Promise<Response<GetCredentialsResponse>> {
    return this.api.get<GetCredentialsResponse>(accountId ? `/v2/accounts/${accountId}/credentials` : `/v2/account/credentials`);
  }

  async createCredential(credential: LocalCredential | ProxyCredential | ApiCredential, accountId?: string | null): Promise<Response<(LocalCredential | ProxyCredential | ApiCredential)>> {
    return this.api.post<(LocalCredential | ProxyCredential | ApiCredential)>(
      accountId ? `/v2/accounts/${accountId}/credentials` : `/v2/account/credentials`,
      { ...credential, metadata: { clientId: ClientId.RISR_ADVANCE } }
    );
  }

  async updateCredentialUsername(credentialType: CredentialTypes.LOCAL | CredentialTypes.PROXY, currentCredentialUsername: string, newCredentialUsername: string, accountId?: string | null): Promise<Response<void>> {
    let url = accountId ? `/v2/accounts/${accountId}/credentials/${credentialType}/${currentCredentialUsername}` : `/v2/account/credentials/${credentialType}/${currentCredentialUsername}`;
    return this.api.put<void>(url, { newUsername: newCredentialUsername });
  }

  async updatePassword(credentialUsername: string, newPassword: string, passwordConfirmation: string, accountId?: string): Promise<Response<void>> {
    let url = accountId ? `/v2/accounts/${accountId}/credentials/local/${credentialUsername}` : `/v2/account/credentials/local/${credentialUsername}`;
    return this.api.put<void>(url, { newPassword, passwordConfirmation });
  }

  async sendPasswordResetEmail(accountId: string, credentialUsername: string): Promise<Response<void>> {
    return this.api.post<void>(`/v2/accounts/${accountId}/credentials/local/${credentialUsername}/password-reset`, {
      metadata: { clientId: ClientId.RISR_ADVANCE }
    });
  }

  async deleteLoginCredential(credential: LocalCredential | ProxyCredential, accountId?: string | null): Promise<Response<void>> {
    return this.api.delete<void>(
      accountId ? `/v2/accounts/${accountId}/credentials/${credential.type}/${credential.username}`
        : `/v2/account/credentials/${credential.type}/${credential.username}`
    );
  }

  async deleteApiCredential(credentialUsername: string, accountId?: string | null): Promise<Response<void>> {
    return this.api.delete<void>(
      accountId ? `/v2/accounts/${accountId}/credentials/api/${credentialUsername}`
        : `/v2/account/credentials/api/${credentialUsername}`
    );
  }
}
